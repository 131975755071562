import React, { useState, useEffect } from "react";
import { Modal as ReactModal } from "react-bootstrap";
import { GetModalByParentActive, GetModal } from "../services/modal-service";
import { useCookies } from "react-cookie";
import "../scss/Modal.scss";

const Modal = () => {
  const [show, setShow] = useState(false);
  const initialModalState = {
    active: false,
    content: "",
    contentImage: "",
    contentImageAltText: "",
    heading: "",
    id: null,
    headerImage: "",
    headerImageAltText: "",
    name: "",
    parentId: null,
    subHeading: "",
    startDate: "",
    endDate: "",
    lastModified: "",
    templateId: "",
  };
  const [modal, setModal] = useState(initialModalState);
  const initialTemplateState = {
    content: "",
    contentImage: "",
    contentImageAltText: "",
    heading: "",
    id: null,
    headerImage: "",
    headerImageAltText: "",
    lastModified: "",
    name: "",
    parentId: "templates",
    subHeading: "",
    templateContent: "",
    templateImage: "",
  }
  const [template, setTemplate] = useState(initialTemplateState);
  const handleClose = () => setShow(false);
  const cookieName = window.location.hostname + "-ds-modal";
  const siteIdCookie = "dsa-site-id";
  const [cookies, setCookie, removeCookie] = useCookies([
    cookieName,
    siteIdCookie,
  ]);
  const [useTemplate, setUseTemplate] = useState(false);
  const [templateContent, setTemplateContent] = useState(null);
  const [id] = useState(window.utag_data?.site_id || cookies[siteIdCookie]);

  useEffect(() => {
    var today = new Date().toISOString().split("T", 1)[0];
    const resetCookie = () => {
      var d = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
      var timestamps = cookies[cookieName]
        ? cookies[cookieName].split("|", 2)
        : [];

      // if the modal was updated in the last 24hrs OR the cookie was created before today
      if (
        Date.parse(modal.lastModified) > d ||
        (cookies[cookieName] && timestamps[0] < today)
      ) {
        return cookies[cookieName]
          ? timestamps[1] === modal.lastModified.toString()
            ? false
            : true
          : false;
      }

      return false;
    };

    if (modal.active === true) {
      var hasCookie = cookies[cookieName] ? true : false;
      var reset = resetCookie();

      if (reset || !hasCookie) {
        setShow(true);
        if (hasCookie) {
          removeCookie(cookieName);
        }
        setCookie(cookieName, today + "|" + modal.lastModified.toString(), {
          maxAge: 86400,
        });
      }
    }
  }, [cookies, setCookie, removeCookie, cookieName, modal]);

  useEffect(() => {
    if (id?.length > 0) {
        GetModalByParentActive(id)
            .then((res) => {
            let modalData = res.data;
            setModal({
                active: modalData?.active || false,
                content: modalData?.content || "",
                contentImage: modalData?.contentImage || "",
                contentImageAltText: modalData?.contentImageAltText || "",
                contentImageContentStream: "",
                contentImageContentStreamExtension: "",
                heading: modalData?.heading || "",
                id: modalData?.id || null,
                headerImage: modalData?.headerImage || "",
                headerImageAltText: modalData?.headerImageAltText || "",
                headerImageContentStream: "",
                headerImageContentStreamExtension: "",
                name: modalData?.name || "",
                parentId: modalData?.parentId || null,
                subHeading: modalData?.subHeading || "",
                lastModified: modalData?.lastModified
                ? new Date(modalData.lastModified)
                : "",
                templateId: modalData?.templateId || "",
            });
            if (modalData?.templateId?.length > 0) {
                GetModal(modalData?.templateId)
                .then((res) => {
                    let templateData = res.data;
                    setTemplate({
                    content: templateData?.content || "",
                    contentImage: templateData?.contentImage || "",
                    contentImageAltText: templateData?.contentImageAltText || "",
                    headerImage: templateData?.headerImage || "",
                    headerImageAltText: templateData?.headerImageAltText || "",
                    heading: templateData?.heading || "",
                    id: templateData?.id || "",
                    lastModified: templateData?.lastModified
                        ? new Date(templateData.lastModified)
                        : "",
                    name: templateData?.name || "",
                    parentId: templateData?.parentId || "templates",
                    subHeading: templateData?.subHeading || "",
                    templateContent: templateData?.templateContent || "",
                    });
                    if (templateData?.templateContent?.length > 0) {
                    setTemplateContent(templateData.templateContent);
                    }
                    setUseTemplate(true);
                })
                .catch((err) => console.log(err));
            }
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  let hasHeaderImage = useTemplate
    ? template?.headerImage?.length > 0
    : modal?.headerImage?.length > 0;
  let hasContentImage = useTemplate
    ? template?.contentImage?.length > 0
    : modal?.contentImage?.length > 0;
  let headerImage = useTemplate ? template?.headerImage : modal?.headerImage;
  let headerImageAltText = useTemplate
    ? template?.headerImageAltText
    : modal?.headerImageAltText;
  let contentImage = useTemplate ? template?.contentImage : modal?.contentImage;
  let contentImageAltText = useTemplate
    ? template?.contentImageAltText
    : modal?.contentImageAltText;
  let heading = useTemplate ? template?.heading : modal?.heading;
  let subHeading = useTemplate ? template?.subHeading : modal?.subHeading;
  let content = useTemplate ? template?.content : modal?.content;

  return (
    <>
      <div data-testid="test" className="d-none" />
      <ReactModal
        className="dsa-modal"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        {templateContent ? (
          <>
            <ReactModal.Header closeButton></ReactModal.Header>
            <ReactModal.Body
              dangerouslySetInnerHTML={{ __html: templateContent }}
            ></ReactModal.Body>
          </>
        ) : (
          <>
            <ReactModal.Header closeButton>
              {hasHeaderImage && (
                <img src={headerImage} alt={headerImageAltText} />
              )}
            </ReactModal.Header>
            <ReactModal.Body className={hasContentImage ? "p-0" : ""}>
              {hasContentImage ? (
                <img
                  className="modal-content-image"
                  src={contentImage}
                  alt={contentImageAltText}
                />
              ) : (
                <div className="fluid-container fr-view">
                  <span
                    className="announcements-modal-heading"
                    dangerouslySetInnerHTML={{ __html: heading }}
                  ></span>
                  <span
                    className="announcements-modal-subheading"
                    dangerouslySetInnerHTML={{ __html: subHeading }}
                  ></span>
                  <span dangerouslySetInnerHTML={{ __html: content }}></span>
                </div>
              )}
            </ReactModal.Body>
          </>
        )}
      </ReactModal>
    </>
  );
};

export default Modal;
