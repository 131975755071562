import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Modal from './components/Modal';
import HealthCheck from './components/HealthCheck';
import { ApmRoute } from '@elastic/apm-rum-react';
import './scss/App.scss';

const App = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/healthcheck' component={HealthCheck}></Route>
                <ApmRoute component={Modal}></ApmRoute>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
