import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
import { init as initApm } from "./apm.shim";
import "./index.scss";

if (window.location.hostname === process.env.PUBLIC_URL) {
  const modalWidgetApm = initApm({
    serviceName: process.env.REACT_APP_APM_SERVICE_NAME,
    serverUrl: process.env.REACT_APP_APM_SERVICE_URL,
    serviceVersion: process.env.REACT_APP_APM_SERVICE_VERSION,
    breakdownMetrics: true,
    environment: process.env.REACT_APP_ENVIRONMENT,
    distributedTracingOrigins: [process.env.REACT_APP_API_BASEURL],
  });

  modalWidgetApm.addFilter((transaction) => {
    return window.location.hostname === process.env.PUBLIC_URL;
  });
}

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById("ds-modal-widget")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
