// shim of https://github.com/elastic/apm-agent-rum-js/blob/master/packages/rum/src/index.js
import {
  createServiceFactory,
  bootstrap,
  isBrowser
} from '@elastic/apm-rum-core'
import { ApmBase } from '@elastic/apm-rum'

/**
 * Use a single instance of ApmBase across all instance of the agent
 * including the instanes used in framework specific integrations
 */
function getApmBase() {
  if (isBrowser && window.modalWidgetElasticApm) {
    return window.modalWidgetElasticApm
  }
  const enabled = bootstrap()
  const serviceFactory = createServiceFactory()
  const apmBase = new ApmBase(serviceFactory, !enabled)

  if (isBrowser) {
    window.modalWidgetElasticApm = apmBase
  }

  return apmBase
}

const apmBase = getApmBase()

const init = apmBase.init.bind(apmBase)

export default init
export { init, apmBase, ApmBase, apmBase as apm }
