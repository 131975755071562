import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_BASEURL ?? "https://modal-api.ds.dev.leadventure.dev";

async function GetModal(id) {
    return axios.get(`${baseUrl}/v1/Modal/${id}`, {})
}

async function GetModalByParentActive(id) {
    return axios.get(`${baseUrl}/v1/Modal/ByParent/${id}/Active`)
}
export { GetModal, GetModalByParentActive };