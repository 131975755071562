import React from 'react';
import '../scss/App.scss';

const HealthCheck = () => {
    return (
        <div className="container-fluid dsa-body-container">
            <div className="container-fluid dsa-body-container-2">
                <div className="row">
                    <div className="col-sm">
                        <p className="dsa-header-2">Health Check Status</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HealthCheck;